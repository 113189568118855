import detailDefault from '../../detailDefault';

export default class detailacm_sales_turnover_plan extends detailDefault { // eslint-disable-line
    load(sAction, data) {
        if (!sAction.dataGet(data.prefix + '/fields/year/value')) {
            sAction.dataSet(data.prefix + '/fields/year/value', new Date().getFullYear());
            sAction.dataSet(data.prefix + '/changes/fields/year', new Date().getFullYear());
        }
    }

    // update(sAction, data) {

    // }
}
