import React, {Suspense} from 'react';
import PropTypes from 'prop-types';
import DetailTabsContainer from 'ROOT/src/components/DetailViewNew/DetailCard/ClassicView/DetailTabsContainer';
import ImmutablePropTypes from 'react-immutable-proptypes';
import sAction from 'sAction';
import Loader from 'ROOT/src/components/loader';
import DetailTabContent from 'ROOT/src/components/DetailViewNew/DetailCard/ClassicView/DetailTabContent';
import DetailAccountsTurnoverTab from './Accounts/DetailAccountsTurnoverTab';
import ReactReportContainer from 'ROOT/src/components/CustomReport/ReactReportContainer';

const DetailInvTabComponent = React.lazy(() => import('ROOT/src/components/DetailViewNew/DetailCard/ClassicView/DetailInvTabComponent'));
const DetailAclAccessTabComponent = React.lazy(() => import('ROOT/src/components/detailView/Users/DetailAclAccessTabComponent'));

export default function DetailCard(props) {
    /**
     * @return {JSX.Element} DetailView Tab
     */
    const getTab = () => {
        const tab = props.tabs.get(props.activeTab);

        switch (tab.type) {
            case 'invitees':
                return (
                    <Suspense fallback={<Loader/>}>
                        <DetailInvTabComponent
                            active={tab.active}
                            prefix={props.prefix}
                            module={props.module}
                            way={`${props.prefix}/customData`}
                            detailway={props.prefix}
                            data={props.customData}
                            key={tab.id}
                        />
                    </Suspense>
                );
            case 'acl_access':
                if (+sAction.dataGet('conf/user/is_admin') !== 1) {
                    return;
                }

                return (
                    <Suspense fallback={<Loader/>}>
                        <DetailAclAccessTabComponent
                            active={tab.active}
                            module={props.module}
                            id={props.id}
                            way={`${props.prefix}/customData`}
                            data={props.customData}
                            key={tab.id}
                        />
                    </Suspense>
                );
            case 'account_turnover':
                return (
                    <DetailAccountsTurnoverTab
                        active={tab.active}
                        module={props.module}
                        id={props.id}
                        way={`${props.prefix}/customData`}
                        data={props.customData}
                        key={tab.id}
                    />
                );
            case 'standard':
                return (
                    <DetailTabContent
                        tab={tab}
                        prefix={props.prefix}
                        module={props.module}
                        fields={props.fields}
                        acl={props.acl}
                        readonly={props.readonly}
                        attemptedSave={props.attemptedSave}
                        tabWay={`${props.prefix}/tabs/${props.activeTab}`}
                        id={props.id}
                    />
                );
            case 'reactReport':
                return (
                    <div id={`detailtab-report-${tab.id}-${props.id}`}>
                        <ReactReportContainer
                            name={tab.class}
                            label={tab.label}
                            reportType={'detailTab'}
                            fullscreenId={`detailtab-report-${tab.id}-${props.id}`}
                        />
                    </div>
                );
            default:
                return;
        }
    };

    return (
        <>
            <DetailTabsContainer
                tabs={props.tabs}
                activeTab={props.activeTab}
                module={props.module}
                way={props.prefix}
                isBoundModule={props.isBoundModule}
            />
            {getTab()}
        </>
    );
}

DetailCard.propTypes = {
    module: PropTypes.string,
    prefix: PropTypes.string,
    tabs: ImmutablePropTypes.listOf(
        ImmutablePropTypes.recordOf({
            id: PropTypes.number,
            name: PropTypes.string,
            type: PropTypes.string,
            active: PropTypes.bool,
            hidden: PropTypes.bool,
            rows: ImmutablePropTypes.listOf(
                PropTypes.oneOfType([ImmutablePropTypes.list, ImmutablePropTypes.map]),
            ),
        }),
    ),
    fields: ImmutablePropTypes.mapOf(
        ImmutablePropTypes.recordOf({
            name: PropTypes.string,
            value: PropTypes.any,
            actEdit: PropTypes.bool,
            customStyle: PropTypes.string,
            customClass: PropTypes.string,
            selectForEdit: PropTypes.bool,
            events: ImmutablePropTypes.list,
            def: ImmutablePropTypes.map,
        }),
    ),
    customData: PropTypes.object,
    acl: ImmutablePropTypes.mapContains({
        delete: PropTypes.bool,
        detail: PropTypes.bool,
        edit: PropTypes.bool,
        export: PropTypes.bool,
        massupdate: PropTypes.bool,
    }),
    activeTab: PropTypes.number,
    readonly: PropTypes.bool,
    attemptedSave: PropTypes.bool,
    id: PropTypes.string,
    isBoundModule: PropTypes.bool,
};
